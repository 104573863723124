import React from 'react'
import ThermoplasticMain from './ThermoplasticMain/ThermoplasticMain'
import MachiningCenters from './MachiningCenters/MachiningCenters'
import Thermopress from './Thermopress/Thermopress'
import PrintingSystems from './PrintingSystems/PrintingSystems'
import CuttingStations from './CuttingStations/CuttingStations'
import WaterjetSystems from './WaterjetSystems/WaterjetSystems'

const Index = () => {
  return (
    <div>
      <ThermoplasticMain />
      <MachiningCenters />
      <Thermopress />
      <PrintingSystems />
      <CuttingStations />
      <WaterjetSystems />
    </div>
  )
}

export default Index
