import React from 'react'
import style from './EngineeringTechnology.module.css'
import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'

const EngineeringTechnology = () => {
  const { t } = useTranslation()
  return (
    <div className={style.engineering} id="engineering">
      <div className={`${'customContainer'} ${style.engineering_wrap}`}>
        <h2 className={style.title}>{t('equipment.title')}</h2>
        <p className={style.desc}>{t('equipment.desc')}</p>
        {/*<Link to={'http://prostir3d.com'} className={style.button}>*/}
        {/*  {t('engineering.button')}*/}
        {/*</Link>*/}
      </div>
    </div>
  )
}

export default EngineeringTechnology
